.ReactModal__Body--open {
    
}

.ReactModalPortal > * {
    opacity: 0;
}

.overlay {
    z-index: 9999;
	position: fixed;
	left: 0;
	top: 0;
    width: 100%;
    height: 100%;
	transition: opacity 500ms $CubicEaseInOut;
    background: rgba(0, 0, 0, 0.6);

    &_after-open {
        opacity: 1;
    }
    &_before-close {
        opacity: 0;
    }

    ::-webkit-scrollbar {
      width: 8px;
    }

    ::-webkit-scrollbar-button {
      display: none;
    }

    ::-webkit-scrollbar-track {
      position: relative;
      background-color: #f8f8fa;
      border-radius: 5px;
    }

    ::-webkit-scrollbar-track-piece {
      background-color: #f8f8fa;
    }

    ::-webkit-scrollbar-thumb {
      background-color: #cacaca;
    }

    ::-webkit-scrollbar-corner {

    }

    ::-webkit-resizer {

    }
}

.scf-modal {
	position: absolute;
	left: 50%;
	top: 20px;
	transform: translate(-50%, 0);
	transition: transform 300ms $CubicEaseInOut, opacity 300ms $CubicEaseInOut;
    background: rgba(255, 255, 255, 1);
    outline: none;
    z-index: 10;
    overflow-y: auto;
    overflow-x: hidden;
    max-height: 95vh;
    font-family: $font;
    color: #000;

    &_after-open {
    	opacity: 1;
        transform: translate(-50%, 0);
    }

    &_before-close {
    	opacity: 0;
        transform: translateX(-50%, 0);
    }

    .input-range {
        .input-range__slider-container {
            background-color: #2e5ba0;
        }
    }

    .thank-you {
        margin: 60px 0;
        text-align: center;
    }
}

.calculation-popup {
    padding: 1px;
    overflow-y: auto;

    .popup-inner {
        position: relative !important;
        top: 5% !important;
        margin-bottom: 5%;
        transform: translate(-50%, 0) !important;
    }
}