.page-enter {
  opacity: 0;
}

.page-enter.page-enter-active {
  opacity: 1;
  transition: opacity 0.4s $QuadEaseOut;
  transition-delay: 0.4s;
}

.page-exit {
  opacity: 1;
}

.page-exit.page-exit-active {
  opacity: 0;
  transition: opacity 0.4s $QuadEaseOut;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);  
  }

  100% {
    transform: rotate(360deg); 
  }
}