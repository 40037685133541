.ReactModal__Body--open {
  overflow: hidden;
}

.ReactModalPortal > * {
  box-sizing: border-box;
}

.ReactModalPortal {
  * {
    box-sizing: border-box;
  }
}

.scf-overlay {
  z-index: 9999;
  position: fixed;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  transition: opacity 400ms $CubicEaseInOut;
  background: rgba(0, 0, 0, 0.75);
  overflow-y: auto;
  width: 100%;

  &_after-open {
    opacity: 1;
  }

  &_before-close {
    opacity: 0;
  }
}

.scf-modal {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  transition: transform 300ms $QuadEaseOut 0.1s, opacity 300ms $QuadEaseOut 0.1s;
  background: rgba(0, 0, 0, 0);
  outline: none;
  border: none;
  opacity: 0;
  overflow: auto;
  max-height: 90vh;
  width: 90%;
  max-width: 700px;

  &_after-open {
    opacity: 1;
  }

  &_before-close {
    opacity: 0;
  }

  .popup-inner {
    position: relative;
    width: 100%;
    background-color: #fff;

    @include HD {
      display: flex;
      flex-flow: row;
      min-height: 550px;
    }

    .popup-top,
    .popup-content {
      @include HD {
        width: 50%;
      }
    }
  }
}

  .summary-popup {
    position: relative;
    background-color: #fff;
    padding: 15px;
    width: 100%;
    max-width: 700px;

    @include vHD {
      padding: 15px 30px 30px;
    }

    .close {
      position: absolute;
      width: 40px;
      height: 40px;
      top: 5px;
      right: 5px;
      cursor: pointer;

      &:before, &:after {
        content: "";
        position: absolute;
        top: 50%;
        left: 5%;
        width: 90%;
        height: 1px;
        background-color: $kiablack;
        transform: rotate(45deg);
      }

      &:after {
        transform: rotate(-45deg);
      }
    }

    .scf-btn {
      font-size: 16px;
      margin-bottom: 30px;
    }

    .popup-top {
      margin-bottom: 30px;

      .model-name {
        margin: 0.2em 0 0.1em;
        font-weight: 700;
        font-size: 2em;
        color: #3c3c3c;

        .model-price {
          font-size: 1em;
          color: $red;
        }

        @media screen and (min-width: 720px) {
          margin-top: 15px;
          position: relative;
          padding-right: 150px;

          .model-price {
            position: absolute;
            top: 0;
            right: 0;
            display: block;
          }
        }
      }

      .model-engine {
        font-weight: 700;
        font-size: 1.5em;
        color: $gray;
      }
    }

    .summary {
      .btn {
        display: inline-block;
        position: relative;
        padding: 15px 0 15px;
        width: 100%;
        margin: 5px 0 20px;
        font-size: 13px;
        font-weight: 700;
        text-align: center;
        color: #fff;
        line-height: 1;
        text-transform: uppercase;
        background-color: $kiablack;
        border-radius: 0;
        -webkit-tap-highlight-color: rgba(0,0,0,0);
        cursor: pointer;
        margin-top: 12px;

        &:active {
          outline: 0;
    }
      }

      .download-button {
        padding: 0 35px;

        .btn {
          &.btn-transparent {
            width: auto;
            border: $kiablack solid 2px;
            background-color: #fff;
            color: $kiablack;
            font-weight: 600;
            display: flex;
            justify-content: center;
            align-items: center;
            padding: 10px 15px;

            img {
              margin-right: 12px;
            }

            &:hover {
              background-color: $kiablack;
              color: #fff;

              img {
                filter: brightness(0) invert(1);
              }
            }
          }
        }
      }
    }

    .product-summary {
      position: relative;
      padding: 35px;
      background-color: #f6f6f6;
      color: #000;

      @media screen and (max-width: 720px) {
        padding: 20px;
      }

      h3 {
        margin: 0;
        margin-bottom: 20px;
        padding-bottom: 20px;
        border-bottom: 1px solid #000;

        @media screen and (max-width: 720px) {
          padding-bottom: 25px;
        }
      }

      .desc {
        position: absolute;
        top: 40px;
        right: 35px;
        
        font-size: 14px;

        @media screen and (max-width: 720px) {
          top: 45px;
          right: auto;
          left: 20px;
        }
      }

      .product-config {
        display: flex;

        @media screen and (max-width: 720px) {
          flex-wrap: wrap;
        }

        .product-config-cell {
          width: 33%;
          padding: 15px 3% 15px 3%;
          border-left: 1px solid #000;

          @media screen and (max-width: 720px) {
            width: 50%;

            &:nth-child(3) {
              border: 0;
              padding-left: 0;
              margin-top: 15px;
            }

            &:last-child {
              margin-top: 15px;
            }
          }

          &-monthly {
            padding-left: 0;
            border: 0;

            &:first-letter {
              text-transform: uppercase;
            }
          }

          .cell-title {
            display: block;
            margin-bottom: 0.4em;
            font-size: 0.9em;
          }

          .cell-value {
            display: block;
            font-weight: 700;
            font-size: 1.6em;

            .units {
              font-size: 0.6em;
            }
          }
        }
      }
    }

    .product-details {
      margin: 30px 0;
      padding-bottom: 15px;
      background-color: #f6f6f6;

      .disclaimer {
        margin: 20px 0 10px;
      }

      ul {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        padding: 0;
        margin: 0;
        list-style: none;

        li {
          padding: 0 0 10px;
          margin: 0 0 10px;
          font-size: 0.9em;
          color: $gray;
          width: 48%;
          border-bottom: 1px solid #c6c6c6;

          &.no-border {
            border: 0;
          }

          @media screen and (max-width: 720px) {
            width: 100%;
            margin-bottom: 15px;
          }

          &:last-child {
            border: 0;

            @media screen and (max-width: 720px) {
              margin: 0;
            }
          }

          &:nth-child(6) {
            border: 0;

            @media screen and (max-width: 720px) {
              border-bottom: 1px solid #c6c6c6;
            }
          }
        }
      }
    }

    .disclaimer {
      font-size: 11px;
      line-height: 1.3;
      color: $gray;

      a {
        text-decoration: underline;
        color: $gray;

        &:hover {
          color: #002c63;
        }
      }
    }

    &.popup-info {
      font-size: 14px;

      .disclaimer {
        padding-top: 25px;
        line-height: 1.4;
        color: #000;
      }
    }

    .contact-form {

      h2 {
        text-transform: uppercase;
        
        text-align: center;
        margin: 40px;
      }

      .buttons {
        @include vHD {
          display: flex;
          justify-content: flex-end;
          
          .scf-btn {
            width: 47%;
          }
        }
      }

      .checkboxes {
        font-size: 11px;
        margin-bottom: 35px;

        @include vHD {
          font-size: 14px;
        }

        label {
          position: relative;
          padding-left: 40px;
          display: block;
          margin: 20px 0;
          line-height: 1.4;
          font-size: 12px;

          span, p span {
            color: #e35205;
          }

          &:before {
            content: "";
            position: absolute;
            left: 0;
            //top: 5px;
            width: 18px;
            height: 18px;
            border: $red solid 1px;
          }

          &:after {
            content: "";
            position: absolute;
            display: none;
            left: 4px;
            top: 4px;
            width: 12px;
            height: 12px;
            background-color: $red;
          }
        }

        input {
          display: none;
        }

        input[type="checkbox"]:checked + label:after {
          display: block;
        }

        .checkbox .input-error {
          position: relative;
          margin-top: -10px;
          padding-left: 40px;
        }
      }

      .inputs {

        @include vHD {
          display: flex;
          flex-wrap: wrap;
          justify-content: space-between;
        }

        .input {
          margin-bottom: 15px;

          @include vHD {
            width: 47%;
          }
        }
      }

      input, .selected {
        position: relative;
        width: 100%;
        font-size: 16px;
        color: #363a48;
        background-color: transparent;
        padding: 18px 22px;
        margin: 7px 0;
        outline: none;
        border: 1px solid #fff;
        box-shadow: 0px 20px 50px 0 rgba(0, 0, 0, 0.1);
      }

      label {
        font-size: 16px;
        
      }

      input:focus, .selected:focus, input:active, .selected:active {
        border-color: $red;
      }
    }

    &.popup-info {
      padding: 35px 25px;
      line-height: 1.3;

      @include vHD {
        padding: 30px 40px;
      }

      p {
        font-size: 14px;
      }
    }
  }
