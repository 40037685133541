
#scf-root {
  font-family: $font;
  font-weight: 400;
  font-size: 12px;
  line-height: 1.4;
  //background-color: $bg-color;
  color: $kiablack;
  
  * {
    box-sizing: border-box;
  }

  h1 {
    font-size: 50px;
    font-weight: 700;
    color: $kiablack;
  }

  h2 {
    margin: .5em 0;
    font-size: 14px;
    font-weight: 400;
    color: $kiablack;
  }

  h3 {
    margin: 1.5em 0;
    font-size: 14px;
    font-weight: 700;
  }

  h4 {
    margin: 1.5em 0;
    font-size: 13px;
    font-weight: 700;
  }


  ul {
    margin: 0;
    padding: 0;
    li {
      list-style: none;
    }
  }

  img {
    display: block;
    margin: 0;
    max-width: 100%;
  }

  a {
    text-decoration: none;
    color: $kiablack;
  }

  input {
    width: 100%;
    padding: 10px 5px;
    background-color: $bg-color;
    color: $kiablack;
    font-size: 14px;
    font-weight: 700;
    border: 0;
  }

  .bold {
    font-weight: 700;
  }

  .app {

  }

  .page {
    padding: 80px 0 0;
    overflow: hidden;
    
    .page-inner {
      padding: 5px 7% 0;
      transition: transform .6s $QuadEaseInOut;
    }

    .page-head {
      h1 {
        margin-bottom: .5em;
      }

      p {
        margin-top: .5em;
      }
    }
  }

  .content {
    margin: 1em 0;
    color: $kiablack;
    text-align: center;
  }

  .btn {
    display: inline-block;
    position: relative;
    padding: 15px 0 15px;
    width: 100%;
    margin: 5px 0 0;
    font-size: 13px;
    font-weight: 700;
    text-align: center;
    color: #fff;
    line-height: 1;
    text-transform: uppercase;
    background-color: $kiablack;
    border-radius: 0;
    -webkit-tap-highlight-color: rgba(0,0,0,0);
    cursor: pointer;

    &:active {
      outline: 0;
    }

    &.white-btn {
      background-color: #fff;
      color: #000;
    }

    &.small-btn {
      margin: 0 0 0 20px;
      padding: 18px 25px 14px;
      font-size: 16px;
      min-width: 0;
    }

    &.summary-btn {
      width: 100%;
      text-transform: initial;
    }

    &.popup-btn {
      width: 60%;
      padding-left: 20px;
      margin-left: auto;
      margin-right: auto;
    }
  }

  .start-page {

  }

  .popup {
    position: fixed;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(255, 255, 255, 0.5);
    z-index: 99999;

    .popup-inner {
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
      background-color: #fff;
      width: 90%;
      max-width: 580px;
      padding: 35px 7%;
      border-radius: 5px;
      box-shadow: rgba(0, 0, 0, 0.3) 0 0 80px;
      font-size: 16px;
      color: #000;

      input {
        padding: 10px 5px 5px;
        width: 300px;
        border-bottom: 2px solid #979797;
        background-color: #fff;
        font-size: 16px;
        font-weight: 400;
      }

      .btn {
        display: block;
        width: 160px;
        margin: 20px auto 0;
      }

      .popup-close {
        position: absolute;
        top: 15px;
        right: 15px;
        width: 33px;
        height: 33px;
        cursor: pointer;

        svg {
          width: 33px;
          height: 33px;
        }
      }
    }

    &.rrso-popup {
      left: 0;
      top: 0;
      max-width: 100%;
      transform: none;
      z-index: 99999;

      .popup-inner {
        font-size: 14px;
        max-height: 90vh;
        overflow-y: auto;
      }
    }
  }

  .download-calculation {
    display: flex;
    align-items: center;

    h2 {
      margin: 2px 0 0;
      height: 33px;
      padding-left: 45px;
      line-height: 34px;
      background-image: url(/assets/download.svg);
      background-size: contain;
      background-position: left center;
      background-repeat: no-repeat;
    }
  }

  .checkboxes {
    font-size: 14px;

    .checkbox {
      margin-top: 20px;
    }

    label {
      position: relative;
      padding-left: 40px;
      display: block;
      //margin: 20px 0 0;
      line-height: 24px;

      &:before {
        content: "";
        position: absolute;
        left: 0;
        //top: 5px;
        width: 20px;
        height: 20px;
        border: $kiablack solid 2px;
      }
    }

    input {
      display: none;
    }

    input[type="checkbox"]:checked + label:before {
      background-color: $kiablack;
    }
  }

  .parsley-errors-list {
    li {
      display: block;
      margin-top: 10px;
      font-size: 12px;
      color: #f55;
    }
  }

  .preloader-holder {
    position: absolute;
    top: 0;
    left: 0;
    display: none;
    width: 100%;
    height: 100%;
    background-color: rgba(255,255,255,0.8);

    &.show {
      display: block;
    }
  }

    .preloader {
      position: absolute;
      left: 50%;
      top: 50%;
      margin-top: -15px;
      margin-left: -15px;
      opacity: 1;
      visibility: visible;
      z-index: 800;
      transition: opacity .5s $CubicEaseInOut, visibility .5s $CubicEaseInOut;

      span {
        position: absolute;
        left: 0;
        top: 0;
        width: 30px;
        height: 30px;
        border: #ddd solid 3px;
        border-bottom: $kiablack solid 3px;
        border-radius: 50%;
        animation: spin 1s linear infinite;
      }
    }

  .popup-message {
    position: absolute;
    top: 0;
    left: 0;
    display: none;
    width: 100%;
    height: 100%;
    background-color: rgba(255,255,255,0.9);

    h2 {
      position: absolute;
      top: 50%;
      left: 0;
      width: 100%;
      margin-top: -30px;
      padding: 0 10%;
      text-align: center;
      font-weight: 700;
    }

    &.show {
      display: block;
    }
  }
}