
	.car-price-heading {
		display: flex;
		align-items: center;
		padding: 0 35px;

		&--text {
			margin-top: 3px;
		}

		span {
			font-size: 1em;
			font-weight: 400;

			.bold {
				font-weight: 700;
			}
		}
	}

	.download-button {
		.btn {
			&.btn-transparent {
				width: auto !important;
				border: $kiablack solid 2px;
				background-color: #fff;
				color: $kiablack;
				font-weight: 400;
				display: flex;
				justify-content: center;
				align-items: center;
			}
		}
	}

	.monthly-fee {
		position: relative;
		padding: 15px 0 5px;
		margin: 15px 0 15px;
		background-color: #fff;
		border-radius: 5px;
		text-align: center;

		.sub-title {
			color: #aaa;
			font-size: 16px;
		}

		.price-wrap {
			position: relative;
			
			.i-icon {
				left: calc(50% + 60px);
			}
		}

		.scf-price {
			font-size: 22px;
			font-weight: 700;
			color: #000;
		}

		.credit-disclaimer {
			margin-top: 10px;
		}

		.rrso {
			position: relative;
			font-size: 24px;
			font-weight: 700;
			color: #000;
		}

		.monthly-loader {
			position: absolute;
			top: 4px;
			right: 4px;
			width: 20px;
			height: 20px;
			border-radius: 50%;
			border-bottom: 3px solid #d3d3d3;
			border-left: 3px solid $kiablack;
			border-right: 3px solid $kiablack;
			border-top: 3px solid #d3d3d3;
			animation: spin 1.2s linear infinite;
		}

		.example-text {
			color: #05141f;
			font-size: 10px;
			padding: 6px 0;
			margin: 0 6px;
			margin-top: 6px;
			border-top: #05141f solid 1px;
		}
	}

	.widget.maxi {
		.monthly-fee {
			display: flex;
			flex-flow: row-reverse;
			flex-wrap: nowrap;
			justify-content: flex-end;
			align-items: center;
			padding-left: 16px;
			padding-right: 16px;

			.example-text {
				max-width: 112px;
				margin: 0;
				border-top: none;
				padding: 16px 16px 16px 0;
				border-right: #05141f solid 1px;
				margin-right: 16px;
			}

			&>div:first-child {
				width: 100%;
			}
		}
	}

	.financing-options {
		margin-bottom: 25px;

	  .option {
	    margin: 0 0 15px;
	    padding: 5px 0;

	    &:nth-child(2) {
	    	margin: 0 0 5px;
	    }

	    h2 {
	    	margin: 0;
	    	font-size: 16px;
	    }

	    .radios {
	    	margin-bottom: 0;
	    }
	  }
	}

	.fee-title {
		color: #aaa;
	}

	.i-icon {
		position: absolute;
		left: calc(50% + 70px);
		top: 2px;
		width: 16px;
		height: 16px;
		border: 1px solid #aaa;
		text-align: center;
		font-size: 12px;
		line-height: 12px;
		cursor: pointer;
		color: #aaa;
		font-weight: 500;
		border-radius: 50%;
	}
