.footer-nav {
	position: fixed;
	bottom: 0;
	left: 0;
	height: 70px;
	width: 100%;
	background-color: $header-color;
	transform: translate(0, 100px);
	transition: transform .5s $CubicEaseOut;
	box-shadow: 1px 0 2px 1px rgba(0, 0, 0, 0.5);

	&.show {
		transform: translate(0,0);
	}

	ul {
		display: flex;
		height: 100%;

		li {
			width: 33.33%;
			height: 100%;
			border-right: 1px solid #2a2a2a;

			&:last-child {
				border-right: 1px solid $header-color;
			}

			a {
				display: block;
				padding-top: 5px;
				width: 100%;
				height: 100%;
				font-size: 10px;
				font-weight: 700;
				text-align: center;
				-webkit-tap-highlight-color: rgba(241, 246, 246, 0.5);
				
				img {
					display: block;
					height: 30px;
					width :auto;
					margin: 0 auto 0px;
				}
			}
		}
	}
}