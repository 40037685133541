@font-face {
  font-family: 'Design';
  src: url('/fonts/designkm.ttf') format('trutype'),
       url('/fonts/designkm.woff') format('woff');
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: 'Design';
  src: url('/fonts/designkb.ttf') format('trutype'), 
       url('/fonts/designkb.woff') format('woff');
  font-weight: 700;
  font-style: normal;
}
$font: 'Tahoma', sans-serif;