.car-price-page {
	display: flex;
	flex-direction: column;
	justify-content: center;
	text-align: center;

	a {
		margin: 30px 0;
	}

	h2 {
		font-size: 30px;
		text-align: left;
		text-align: center;
	}

	.car-input {
		display: inline-block;
		margin-bottom: 80px;
		border-bottom: 2px solid #d8d8d8;
		width: 304px;

		input {
			padding: 2px 5px;
			font-size: 40px;
			font-weight: 700;
			border: 0;
			line-height: 1.1;
		}
	}

	.btn {
		margin-top: 120px;
		margin-bottom: 50px;
	}
}