.welcome-page {
	width: 100%;
	height: 100%;
	background-image: url(/assets/welcome_bg.png);
	background-size: cover;
	background-position: center;
	background-repeat: no-repeat;
	display: flex;
	flex-direction: column;
	justify-content: center;

	.page-inner {
		text-align: center;
	}

	h1 {
		padding: 0 10%;
		margin-bottom: 100px;
		font-size: 60px;
		font-weight: 400;
		color: #fff;
		text-align: center;
		line-height: 1.2;
	}
}