.radios {
	display: flex;
	margin: 10px 0;
	flex-wrap: wrap;
	justify-content: space-between;
}

.radio {
	position: relative;
	padding: 10px 10px 10px;
	margin-right: 0;
	margin-bottom: 7px;
	width: 48%;
	font-size: 11px;
	font-weight: 400;
	color: $kiablack;
	line-height: 1;
	text-align: center;
	border-radius: 0;
	cursor: pointer;
	border: 2px solid $kiablack;
	transition: border .1s $QuadEaseOut;
	display: flex;
	flex-flow: row;
	justify-content: center;
	align-items: center;

	span {
		display: inline-block;
		text-align: center;
		font-size: 8px;
		font-weight: 600;
	}

	img {
		width: 30px;
		margin-right: 5px !important;
	}

	&.active {
		color: #fff;
		background-color: $kiablack;

		&:before {
			border: 2px solid #fff;
			background-color: #7590bb;
		}

		&:after {
			transform: scale(1);
		}

		&:hover {
			border: 2px solid $kiablack;
		}

		img {
			filter: brightness(0) invert(1);
		}
	}

	&:hover {
		border: 2px solid $kiablack;
	}

	&.disabled {
		color: $gray;
		border-color: $gray;
		cursor: default;
	}
}

.radios-text-top {
	font-weight: 700;
	font-size: 14px;
	display: block;
	margin: 0 0 8px;
}

.widget.maxi {
	.radios {
		.radio {
			span {
				font-size: 12px;
			}

			img {
				width: 40px;
			}
		}
	}
}