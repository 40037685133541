.range-slider {
  position: relative;
	margin: 38px 5px 30px;
  width: calc(100% - 10px);

  .handle {
  	position: absolute;
    margin-left: -13px;
    margin-top: -10px;
    z-index: 3;
    width: 24px;
    height: 24px;
    cursor: pointer;
    border-radius: 50%;
    background-color: #fff;
    border: 6px solid #ea0029;
  }

  .track {
  	position: absolute;
    height: 5px;
    z-index: 2;
    background-color: $kiablack;
    cursor: pointer;
  }

  .rail {
  	position: absolute;
  	z-index: 1;
  	top: -10px;
	  width: 100%;
	  height: 26px;
	  cursor: pointer;
	  border-radius: 3px;
	  background-color: #f4f5f6;

    &-inner {
    	position: absolute;
    	top: 10px;
    	left: 0;
    	width: 100%;
    	height: 5px;
    	background-color: #979da1;
    }

    &:before {
    	content: "";
    	position: absolute;
    	top: 7px;
    	left: -5px;
    	width: 11px;
    	height: 11px;
    	background-color: $kiablack;
    	border-radius: 50%;
    }

    &:after {
    	content: "";
    	position: absolute;
    	top: 7px;
    	right: -5px;
    	width: 11px;
    	height: 11px;
    	background-color: #979da1;
    	border-radius: 50%;
    }
  }

  .min-value {
  	position: absolute;
  	left: -5px;
  	top: -35px;
  	font-size: 12px;
  	color: #000;
  }

  .max-value {
  	position: absolute;
  	right: -5px;
  	top: -35px;
  	font-size: 12px;
  	color: #000;
  }

  .dots {
    position: relative;

    .dot {
      position: absolute;
      top: -3px;
      width: 11px;
      height: 11px;
      margin-left: -5px;
      background-color: #979da1;
      border-radius: 50%;

      &.active {
        background-color: $kiablack;
      }
    }
  }
}