.timer-popup {
	z-index: 800;
	position: absolute;
	top: 0;
	left: 0;
	display: flex;
	flex-direction: column;
	justify-content: center;
	width: 100%;
	height: 100%;
	color: #fff;
	text-align: center;
	background-color: rgba(1,146,92,0.95);

	h2 {
		padding: 0 20%;
		color: #fff;
		font-size: 25px;
	}

	.timer {
		font-size: 30px;
	}

	.btn {
		width: 250px;
		margin: 50px auto 70px;
	}
}