.widget {
	z-index: 9998;
	position: fixed;
	bottom: calc(100% - 530px);
	//right: calc(50% - 700px);
	right: 20px;
	background-color: $sand;
	padding: 0;
	box-shadow: 0 0 40px 0 rgba(192, 192, 192, 0.5);

	@media screen and (max-width: 1366px) {
		right: 20px;
		bottom: 10px;
	}

	@media screen and (max-width: 480px) {
		position: fixed;
		left: 0;
		bottom: 0;
		right: auto;
		width: 100%;
		border-radius: 0;
	}
	
	.widget-top {
		position: relative;
		padding: 8px 0;
		border-radius: 7px;
		user-select: none;
		cursor: move;

		h2 {
			margin: 0;
			font-size: 15px;

			font-weight: 700;
			text-align: center;
		}

		span {
			display: block;
			font-size: 12px;
		}
	}

	.widget-content {
		padding: 0 10px 10px 10px;
	}

	&.mini, &.micro {
		width: 200px;

		@media screen and (max-width: 480px) {
			width: 100%;
			height: 74px;
			padding: 0;
		}

		.widget-top {
			@media screen and (max-width: 480px) {
				display: none;
			}
		}

		.monthly-fee {
			//cursor: pointer;
			margin: 10px 0;
		}

		.radios {
			display: none;

			@media screen and (min-width: 480px) {
				display: flex;
			}
		}

		.radios-text-top {
			display: none;

			@media screen and (min-width: 480px) {
				display: block;
			}
		}

		.scf-btn {
			@media screen and (max-width: 480px) {
				padding: 31px 15px !important;
				margin: 0;
				position: absolute !important;
				right: 0;
				top: 0;
				font-size: 12px !important;
				height: 74px;
			}
		}

		.widget-content {
			@media screen and (max-width: 480px) {
				display: flex;
				flex-direction: column;
				justify-content: center;
				height: 100%;
				padding: 0 125px 0 3%;

				.monthly-fee {
					display: flex;
					flex-wrap: wrap;
					color: #000;
					background-color: transparent;
					padding: 5px 0;
					margin: 0;
					font-size: 16px;

					.fee-title {
						color: #000;
						padding-top: 3px;
						padding-left: 30px;
					}

					.sub-title {

					}
				}

				@media screen and (min-width: 481px) {
					.monthly-fee {
						font-size: 22px;
					}
				}

				.roll-up {
					position: absolute;
					top: 0;
					right: 20px;
					margin: 0;
					height: 100%;
					line-height: 48px;

					&:after {
						content: "";
						position: absolute;
						left: -20px;
						top: 14px;
						width: 1px;
						height: 20px;
						background-color: rgba(255,255,255,0.2);
					}
				}
			}
		}

		.radios {
			.radio {
				padding: 5px 10px;
			}
		}
	}

	&.maxi {
		width: 350px;
		padding: 0px 10px 10px;

		@media screen and (max-width: 480px) {
			top: 0px;
			bottom: auto;
			height: 110%;
			width: 100%;

		}

		.widget-top {
			h2 {
				text-align: left;
				padding-bottom: 10px;
			}
		}

		.widget-disclaimer {
			bottom: 10%;
		}
		
		.credit-disclaimer {
			.credit-disclaimer-wrap {
				@media screen and (max-width: 480px) {
					//display: block;
					justify-content: center;
					
					.rrso, .credit-disclaimer-link {
						//width: 100%;
						text-align: center;
					}
				}
			}
		}
	}

	.rrso {
		//display: inline-block;
		position: relative;

		@media screen and (max-width: 480px) {
			font-size: 15px;
			//width: 50%;
			padding: 4px 0 0;
		}

		font-size: 20px;
		color: #000;
	}

	.monthly-fee {
		position: relative;
		//height: 100px;
		padding: 10px 0 10px;
		margin: 10px 0 15px;
		font-size: 22px;
		line-height: 1;
		background-color: #fff;
		border-radius: 3px;
		text-align: center;

		&-leasing {
			//height: 82px;
		}

		p {
			margin: 0;
		}

		.fee-title, .sub-title {
			display: block;
			margin-top: 1px;
			margin-bottom: 5px;
			font-size: 11px;
			color: #aaa;
			display: block;

		}

		.sub-title {
			margin-bottom: 8px;
			color: #000;

			@media screen and (max-width: 480px) {
				//display: none;
				width: 100%;
				text-align: left;
			}
		}
		
		.small {
			font-size: 15px;
			font-weight: 600;
			line-height: 1.2;
		}

		.monthly-loader {
			position: absolute;
			top: 4px;
			right: 4px;
			width: 15px;
			height: 15px;
			border-radius: 50%;
			border-bottom: 2px solid $sand;
			border-left: 2px solid $red;
			border-right: 2px solid $red;
			border-top: 2px solid $sand;
			animation: rotate 1.2s linear infinite;
		}
	}


	.roll-down, .roll-up {
		position: absolute;
		top: 0;
		right: 0px;
		width: 21px;
		height: 21px;
		cursor: pointer;

		&:after {
			content: "";
			position: absolute;
			top: 9px;
			left: 2px;
			width: 17px;
			height: 3px;
			background-color: $red;
		}
	}

	.roll-up {
		&:before {
			content: "";
			position: absolute;
			top: 2px;
			left: 9px;
			width: 3px;
			height: 17px;
			background-color: $red;
		}
	}

	.entry-fee, .time, .limit {
		font-size: 12px;

		span {
			font-size: 14px;

		}
	}

	.scf-btn {
		padding: 15px 0;
		margin-top: 5px;
		font-size: 14px;
		letter-spacing: 0.05em;
		text-transform: uppercase;
		background: #3c3c3c;

		&:before, &:after {
			display: none;
		}

		&:hover {
			&:before, &:after {
				display: block;
			}
		}
	}
	
	.credit-disclaimer {
		display: block;
		margin: 7px 0 5px;
		font-size: 11px;
		line-height: 1.15;
		text-align: center;
		color: $red;
		width: 100%;

		.credit-disclaimer-wrap {
			display: flex;

			@media screen and (min-width: 480px) {
				justify-content: center;
			}
		}

		@media screen and (max-width: 480px) {
			margin: 0;
			text-align: left;
		}

	}

	.price-wrap {
		text-align: center;
	}

	.price {
		position: relative;
		display: inline-block;
	}

	.i-icon {
		position: absolute;
		left: calc(50% + 70px);
		top: 2px;
		width: 16px;
		height: 16px;
		border: 1px solid #aaa;
		text-align: center;
		font-size: 12px;
		line-height: 12px;
		cursor: pointer;
		color: #aaa;
		border-radius: 50%;
	}

	.widget-disclaimer {
		z-index: 100;
		position: absolute;
		bottom: 118%;
		left: 3%;
		width: 94%;
		padding: 10px;
		font-size: 11px;
		line-height: 1.3;
		background-color: $sand;

		.close-disclaimer {
			position: absolute;
			top: 0;
			right: 0;
			width: 24px;
			height: 24px;

			&:before, &:after {
				content: "";
				position: absolute;
				top: 6px;
				left: 11px;
				width: 2px;
				height: 12px;
				background-color: #000;
				transform: rotate(45deg);
			}

			&:after {
				transform: rotate(-45deg);
			}
		}

		@media screen and (min-width: 480px) {
			bottom: 0;
			left: auto;
			right: 105%;
			width: 550px;
		}

		&.top-left {
			bottom: auto;
			top: 0;
		}

		&.top-right {
			bottom: auto;
			top: 0;
			right: auto;
			left: 105%;
		}

		&.bottom-right {
			right: auto;
			left: 105%;
		}
	}
}

@keyframes rotate {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
